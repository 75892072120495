<script setup>
import { onMounted, onUnmounted, ref } from '@/js/import/importHead.js';
import { useRouter, useRoute } from 'vue-router';
import { scrollToSection } from '@/js/use/scrollUtils';
import { useSEO } from '@/js/use/useSEO';
import { inject } from 'vue';
import { ScrollToTopButton, Button } from '@/js/import/importComponents.js';
import { Header, Welcome, Parcours, Discovery } from '@/js/import/importView.js';
import { photo_profil, doula_accueil } from '@/js/import/images/home/homeImages.js';

const $logo = inject('$logo');
const router = useRouter();
const route = useRoute();

const title = 'Doula accompagnatrice naissance dans l\'Aisne';
const description = 'Nathalité Doula 02 propose un accompagnement personnalisé avant, pendant et après l\'accouchement. Doula professionnelle et à l\'écoute, Nathalie vous offre soutien émotionnel et conseils pour une grossesse sereine et un accouchement en douceur';

onMounted(() => {
  if (route.query.scrollTo) {
    scrollToSection(route.query.scrollTo, 3500);
  }
});

const jsonLd = {
  "@context": "https://schema.org",
  "@type": "Person",
  "name": "NatHalité Doula 02",
  "jobTitle": "Doula",
  "description": description,
  "image": photo_profil,
  "url": "https://nathalitedoula02.com",
  "sameAs": [
    "https://www.facebook.com/nathalitedoula02",
    "https://www.instagram.com/votrecompteinstagram"
  ],
  "address": {
    "@type": "PostalAddress",
    "addressLocality": "Saint-Quentin",
    "addressRegion": "Aisne",
    "addressCountry": "France"
  },
  "telephone": "+33123456789",
  "email": "contact@nathalitedoula02.com"
};

useSEO({
  title: 'Doula accompagnatrice naissance dans l\'Aisne',
  description: 'Nathalité Doula 02 propose un accompagnement personnalisé avant, pendant et après l\'accouchement. Doula professionnelle et à l\'écoute, Nathalie vous offre soutien émotionnel et conseils pour une grossesse sereine et un accouchement en douceur',
  keywords: 'Doula 02, Doula, NatHalite Doula,NatHalité Doula, Doula Saint-Quentin, Doula Laon, Doula Soissons, Doula Aisne, Accompagnatrice Naissance',
  image: photo_profil,
  url: '/',
  schema: jsonLd
});

</script>



<template>
  <div class="home-page">

    <Header itemprop="header"></Header>


    <!-- Home section -->
    <main id="home" itemprop="mainContentOfPage" itemscope itemtype="https://schema.org/WebPageElement">

      <!-- welcome section -->
      <Welcome itemprop="mainEntity"></Welcome>

      <!-- parcours section -->
      <Parcours></Parcours>

      <!-- discovery section -->
      <Discovery></Discovery>
    </main>

    <!-- Scroll to Top button -->
    <div>
      <ScrollToTopButton/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
    ScrollToTopButton,
    Button
  },
};
</script>

<style lang="scss" scoped>
main {
  overflow: hidden;
  padding-top: rem(0);
}

.home-page {

  padding-top: rem(101); 
}
</style>