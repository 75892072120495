import { useHead } from '@vueuse/head'

export function useSEO({ 
  title, 
  description, 
  keywords, 
  image, 
  url,
  type = 'website',
  schema = null 
}) {
  const baseUrl = 'https://nathalitedoula02.com'
  
  useHead({
    title,
    meta: [
      { name: 'description', content: description },
      { name: 'keywords', content: keywords },
      { name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
      // Open Graph
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:image', content: image },
      { property: 'og:url', content: `${baseUrl}${url}` },
      { property: 'og:type', content: type },
      // Language
      { 'http-equiv': 'content-language', content: 'fr' },
    ],
    link: [
      { rel: 'canonical', href: `${baseUrl}${url}` }
    ],
    ...(schema && {
      script: [
        {
          type: 'application/ld+json',
          children: JSON.stringify(schema)
        }
      ]
    })
  })
} 