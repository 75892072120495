<template>
  <form @submit.prevent="handleSubmit" class="contact-form" novalidate itemprop="potentialAction" itemscope itemtype="https://schema.org/Action">
    <div class="row">
      <div class="form-group">
        <label for="name" itemprop="name">Nom</label>
        <input type="text" id="name" placeholder="Votre nom" name="name" v-model="name" itemprop="name">
      </div>
      <div class="form-group">
        <label for="firstName" itemprop="name">Prénom</label>
        <input type="text" id="firstName" placeholder="Votre prénom" name="firstName" v-model="firstName" required title="Veillez saisir votre Prénom" itemprop="name">
        <transition name="fade">
          <div class="bloc-error" v-if="errors.firstName"><span class="error-message">{{ errors.firstName }}</span></div>
        </transition>
      </div>
    </div>
    <div class="row">
      <div class="form-group">
        <label for="phone" itemprop="name">Téléphone</label>
        <input type="tel" id="phone" placeholder="Votre téléphone" name="phone" v-model="phone" itemprop="name">
      </div>
      <div class="form-group">
        <label for="email" itemprop="name">Email</label>
        <input type="email" id="email" placeholder="Votre email" name="email" v-model="email" required itemprop="name">
        <transition name="fade">
          <div class="bloc-error" v-if="errors.email"><span class="error-message">{{ errors.email }}</span></div>
        </transition>
      </div>
    </div>
    <div class="row row-area">
      <div class="form-group-area">
        <label for="message">Message</label>
        <textarea id="message" placeholder="Votre message" name="message" v-model="message" required></textarea>
        <transition name="fade">
          <div class="bloc-error" v-if="errors.message"><span class="error-message">{{ errors.message }}</span></div>
        </transition>
      </div>
    </div>
    <Button 
      class="submit-btn"  
      type="submit" 
      :colorClass="'bg-purple text-light'" 
      buttonClass="button" 
      :hoverColorClass="'hover-bg-nav hover-text-link'"
      buttonText="Envoyez"
    />

    <transition name="fade">
      <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>
    </transition>
  </form>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import Button from '@/components/Button.vue';

const router = useRouter();

// Initialisation des refs
const name = ref('');
const firstName = ref('');
const phone = ref('');
const email = ref('');
const message = ref('');
const errorMessage = ref('');
const errors = ref({});

const validateForm = () => {
  errors.value = {}; // Réinitialiser les erreurs

  if (!firstName.value) {
    errors.value.firstName = 'Le prénom est requis';
  }
  if (!email.value) {
    errors.value.email = 'L\'email est requis';
  }
  if (!message.value) {
    errors.value.message = 'Le message est requis';
  }

  return Object.keys(errors.value).length === 0;
};

const resetForm = () => {
  name.value = '';
  firstName.value = '';
  phone.value = '';
  email.value = '';
  message.value = '';
  errors.value = {};
};

const handleSubmit = async (event) => {
  event.preventDefault();
  if (!validateForm()) return;

  try {
    await axios.post('/api/send-email', {
      name: name.value,
      firstName: firstName.value,
      phone: phone.value,
      email: email.value,
      message: message.value,
    });

    // Envoi de l'événement à GTM
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'form_submission',
      'formName': 'contact_form',
      'formStatus': 'success'
    });
    
    router.push({
      name: 'ThanxMessage',
      params: { name: firstName.value }
    });
    
    resetForm();
  } catch (error) {
    // En cas d'erreur, vous pouvez aussi tracker
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'form_submission',
      'formName': 'contact_form',
      'formStatus': 'error'
    });

    console.error('Erreur:', error);
    errorMessage.value = "Une erreur s'est produite lors de l'envoi du message";
  }
};
</script>

<style lang="scss" scoped>

@import '@/styles/contact.scss';

.contact-form{
}

.submit-btn{
  margin:auto;
  width: 8vw ;
  @include font-size-responsive(rem(11), rem(14)); 
}

</style>