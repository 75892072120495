<script setup>
import { ref, useHead, onMounted } from '@/js/import/importHead.js';
import { ScrollToTopButton, Modal } from '@/js/import/importComponents.js';
import { useModal } from '@/js/use/useModal';
import { useAuth } from '@/composables/useAuth';
import mutuelleNostrumCare from '@/assets/forfaits/mutuelle_nostrum_care.jpg';
import mutuelleNostrumCare2 from '@/assets/forfaits/mutuelle_nostrum_care2.jpg';
import axios from 'axios';

const title = ref('Mes forfaits');
const { isModalOpen, modalImage, openModal, closeModal } = useModal();
const { isAdmin } = useAuth();

const forfaits = ref([
  {
    title: 'Accompagnement prénatal',
    defaultPrice: '1h30 à 60€ ou 2h à 80€',
    description: 'désire d\'enfant - grossesse - accouchement - écoute - bienveillance',
    image: new URL('@/assets/forfaits/forfait_accompagnement_prenatal.png', import.meta.url).href,
    alt: 'icon accompagnement prénatal'
  },
  {
    title: 'Accompagnement Postnatal',
    defaultPrice: '2h à 80€ ou 3h à 100€',
    description: 'écoute - soutien émotionnel - soutien physique - soutien logistique - soutien maternel',
    image: new URL('@/assets/forfaits/forfait_accompagnement_postnatal.png', import.meta.url).href,
    alt: 'icon accompagnement postnatal'
  },
  {
    title: 'Forfait Doula',
    defaultPrice: '4 rencontres d\'1h30 à 220€',
    description: 'Forfait prénatal et postnatal',
    image: new URL('@/assets/forfaits/forfait_forfait_doula.png', import.meta.url).href,
    alt: 'icon forfait doula'
  }
]);

const editingPrice = ref(null);
const prices = ref({});

// Charger les prix depuis la base de données
const loadPrices = async () => {
  try {
    console.log('Tentative de chargement des prix...');
    const response = await axios.get('/api/prices');
    
    if (!response.data) {
      console.error('Pas de données reçues');
      return;
    }

    prices.value = response.data.reduce((acc, price) => {
      acc[price.title] = price.price;
      return acc;
    }, {});
    
    console.log('Prix chargés avec succès:', prices.value);
  } catch (error) {
    console.error('Erreur détaillée lors du chargement des prix:', error);
    if (error.response) {
      console.error('Erreur serveur:', error.response.status);
      console.error('Message:', error.response.data);
    }
  }
};

// Formater le prix pour l'affichage
const formatPrice = (price) => {
  if (!price) return '';
  
  // Si le prix est déjà une chaîne de caractères, le retourner tel quel
  if (typeof price === 'string') return price;
  
  // Si le prix a la nouvelle structure avec option1 et option2
  if (price.option1 && price.option2) {
    return `${price.option1.duration} à ${price.option1.amount}€ ou ${price.option2.duration} à ${price.option2.amount}€`;
  }
  
  // Pour les prix par défaut qui pourraient avoir un format différent
  if (typeof price === 'object') {
    return price.defaultPrice || '';
  }
  
  return '';
};

// Démarrer l'édition d'un prix
const startEditingPrice = (forfait) => {
  editingPrice.value = {
    title: forfait.title,
    price: prices.value[forfait.title] || formatPrice(forfait.defaultPrice)
  };
};

// Sauvegarder le nouveau prix
const savePrice = async (forfait) => {
  try {
    console.log('Tentative de sauvegarde du prix pour:', forfait.title);
    const response = await axios.post('/api/prices', {
      title: forfait.title,
      price: editingPrice.value.price
    });
    
    console.log('Réponse de sauvegarde:', response.data);
    prices.value[forfait.title] = response.data.price;
    editingPrice.value = null;
  } catch (error) {
    console.error('Erreur détaillée lors de la sauvegarde:', error);
    alert('Erreur lors de la sauvegarde du prix. Veuillez réessayer.');
  }
};

// Annuler l'édition
const cancelEditing = () => {
  editingPrice.value = null;
};

onMounted(() => {
  loadPrices();
});

useHead({
  title: title.value,
  meta: [
    { charset: 'UTF-8' },
    { name: 'description', content: 'Découvrez l\'accompagnement par une doula pendant l\'accouchement avec NatHalité Doula. Un soutien émotionnel et physique pour une naissance sereine.' },
    { name: 'keywords', content: 'accompagnement doula, accouchement, soutien émotionnel, NatHalité Doula, prix, forfait, doula' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
  ],
  script: [
    { src: 'https://schema.org/WebPageElement', type: 'application/ld+json' },
    { src: 'https://schema.org/ItemList', type: 'application/ld+json' },
    { src: 'https://schema.org/Offer', type: 'application/ld+json' },
    { src: 'https://schema.org/FAQPage', type: 'application/ld+json' },
  ],
});

</script>

<template>
  <main id="forfaits" itemprop="mainContentOfPage" itemscope itemtype="https://schema.org/WebPageElement">
    <div class="parallax">

    <header class="container box-shadow-1 header header-parallax-bg" itemprop="headline">
      <h1 class="text-light">{{ title }}</h1>
    </header>

    <section>
      <div class="forfaits-container container" itemprop="mainEntity" itemscope itemtype="https://schema.org/ItemList">
        <meta itemprop="name" content="Liste des forfaits" />
        <div class="forfaits-group">
          <div class="forfaits-card" v-for="forfait in forfaits" :key="forfait.title" itemprop="itemListElement" itemscope itemtype="https://schema.org/Offer">
            <div class="forfaits-card-header">
              <h5 class="forfait-card-title" itemprop="name">{{ forfait.title }}</h5>
              <img :src="forfait.image" :alt="forfait.alt" class="forfait-icon" itemprop="image" /> <!-- Ajout de l'icône -->
            </div>
            <div class="separator"></div>
            <div class="forfaits-card-body">
              <div class="forfaits-card-body-row">
                <div class="forfait-row forfait-price-block">
                  <!-- Prix en mode édition -->
                  <div v-if="isAdmin && editingPrice?.title === forfait.title" class="price-edit">
                    <input 
                      v-model="editingPrice.price"
                      type="text"
                      class="price-input"
                      @keyup.enter="savePrice(forfait)"
                    >
                    <div class="edit-actions">
                      <button @click="savePrice(forfait)" class="btn-save">✓</button>
                      <button @click="cancelEditing" class="btn-cancel">✕</button>
                    </div>
                  </div>
                  <!-- Prix en affichage normal -->
                  <div v-else class="price-display">
                    <p class="card-text" itemprop="price">
                      {{ prices[forfait.title] ? formatPrice(prices[forfait.title]) : formatPrice(forfait.defaultPrice) }}
                    </p>
                    <button 
                      v-if="isAdmin"
                      @click="startEditingPrice(forfait)"
                      class="btn-edit-price"
                      title="Modifier le prix"
                    >
                      ✎
                    </button>
                  </div>
                </div>
                <div class="separator"></div>
                <div class="forfait-row forfait-description-block">
                  <p class="card-text" itemprop="description">{{ forfait.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>

    <article id="forfait-info" class="container box-shadow-1" itemprop="mainEntity" itemscope itemtype="https://schema.org/FAQPage">
      <h2 itemprop="headline">Comment payer ?</h2>
      <p itemprop="text">
        J'accepte d'être payer en plusieurs fois , tout accompagnement doit être payer le jour même du rdv 
        Si c'est un forfait , le paiement de chaque mensualité sera à payer avant les rdv d'accompagnement. 
        J'accepte d'être payer par virement bancaire, par paylib, en espèces et avec le site chèque emploi service 
        ,une fiche vous sera remis avec les coordonnées pour ce service. Nous pourrons l'effectuer les modalités CESU+ 
        ensemble. 
      </p>
      <p itemprop="text">Elsee rembourse la médecine douce pour les femmes.( La photo résume un peu .)
        Abonnez-vous à Elsee et votre accompagnement doula vous sera remboursé
      </p>
      <p itemprop="text">
        Je te joins des photos avec les explications. La mutuelle nostrum Care rembourse les frais d'accompagnement avec une Doula. (Voir photo)
      </p>
      <p itemprop="text">
        Puis la Doula peut être payer en chèque emploi service, vous bénéficiez d'un crédit d'impôt. (Voir photo )
      </p>
      <div class="forfait-img-container">
        <img class="responsive-img forfait-img" :src="mutuelleNostrumCare" alt="mutuelle nostrum care" @click="openModal(mutuelleNostrumCare)" />
        <div class="forfait-img-container">
        <img class="responsive-img forfait-img" :src="mutuelleNostrumCare2" alt="mutuelle nostrum care" @click="openModal(mutuelleNostrumCare2)" />
      </div>
      </div>
    </article>

    <!-- Scroll to Top button -->
    <div>
      <ScrollToTopButton/>
    </div>

    <!-- Modale pour afficher l'image en grand format -->
    <Modal :isOpen="isModalOpen" :imageSrc="modalImage" @close="closeModal" />
  </main>
</template>


<style lang="scss" scoped>



.parallax {
    background-image: url('@/assets/forfaits/forfait-background.jpg');
    z-index: -1;

}

    .forfaits-container {
      background-color: #5e0c2969;
      width: 100%;
      height: auto;

    }


#forfaits {

  .forfaits-group {
    @include flex-row;
    flex-wrap: wrap;
    justify-content: space-between; /* Espace entre les cartes */
    gap: rem(1); /* Espace entre les cartes */
    
    



    .forfaits-card {
      flex: 1 1 calc(30% - 1rem); /* 3 cartes par ligne */
      max-width: calc(30% - 1rem); /* Limite la largeur maximale des cartes */
      min-height: rem(300);
      border: 2px solid $purple;
      border-radius: 5px;
      @extend .bg-body;
      box-shadow: 0 0 10px 0 #780462;
      margin: rem(8); /* Ajout d'un espace entre les cartes */

      .separator {
          width: 100%;
          height: 1px;
          @extend .bg-purple;
          padding: 0;
        }

      .forfaits-card-header {
        padding: rem(8) rem(16);
        background-color: $bg-section-1;
        color: $purple;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        @include flex-column;
        justify-content: center;
        align-items: center;
        gap: rem(16);
        border-bottom: 1px solid $purple;

        .forfait-icon {
        width: rem(90); 
        height: rem(90); 
        margin-bottom: rem(16);
      }

        .forfait-card-title {
          @include font-size-responsive(0.8rem, 1.2rem);
          margin-bottom : rem(0.5);
        }
      }

      .forfaits-card-body {
        font-size: 1.1rem;
        color: #333;
        width: 100%;
        @include flex-column;

        justify-content: center;
        text-align: center;
        flex: 1;

        .forfaits-card-body-row {
          text-align: center;

          .forfait-price-block {

            background-color: white;
            padding: rem(8);

          }

          .forfait-row {
            gap: 0.5rem;
          }
        }



        .forfait-description-block {
          padding:  rem(16);
        }
      }
    }
  }

  #forfait-info {
    @include flex-column;
    gap: 2rem;
    justify-content: center;
    font-size: 1.2rem;
    background-color: $bg-section-3;
  }

  .forfait-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    
    .forfait-img {
      max-width: 180px;
      cursor: pointer;
      transition: transform 0.8s ease;
    }

    .forfait-img:hover {
      transform: scale(1.1);
    }
  }

  /* Media Queries pour les tailles d'écran */
  @media (min-width: 1024px) {
    .forfaits-group {
      .forfaits-card {
        flex: 1 1 calc(30% - 2rem); /* 3 cartes par ligne */
        max-width: calc(30% - 2rem); /* Limite la largeur maximale des cartes */
      }
    }

    .forfaits-card-header .forfait-card-title {
      font-size: 1.5rem; /* Taille de police plus grande pour les grands écrans */
    }

    .forfaits-card-body {
      font-size: 1.2rem; /* Taille de police plus grande pour les grands écrans */
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .forfaits-group {
      .forfaits-card {
        flex: 1 1 calc(45% - 1rem); /* 2 cartes par ligne */
        max-width: calc(45% - 1rem); /* Limite la largeur maximale des cartes */
      }
    }

    .forfaits-card-header .forfait-card-title {
      font-size: 1.3rem; /* Taille de police moyenne pour les écrans moyens */
    }

    .forfaits-card-body {
      font-size: 1.1rem; /* Taille de police moyenne pour les écrans moyens */
    }
  }

  @media (max-width: 767px) {
    .forfaits-group {
      .forfaits-card {
        flex: 1 1 calc(100% - 1rem); /* 1 carte par ligne */
        max-width: calc(100% - 1rem); /* Limite la largeur maximale des cartes */
      }
    }

    .forfaits-card-header .forfait-card-title {
      font-size: 1.2rem; /* Taille de police plus petite pour les petits écrans */
    }

    .forfaits-card-body {
      font-size: 1rem; /* Taille de police plus petite pour les petits écrans */
    }
  }
}

.forfait-icon {
  width: 30px; /* Ajustez la taille de l'icône selon vos besoins */
  height: 30px; /* Ajustez la taille de l'icône selon vos besoins */
  margin-left: 10px; /* Espace entre le titre et l'icône */
}

.price-edit {
  display: flex;
  gap: rem(8);
  align-items: center;

  .price-input {
    padding: rem(4) rem(8);
    border: 1px solid $purple;
    border-radius: rem(4);
    width: 100%;
  }

  .edit-actions {
    display: flex;
    gap: rem(4);

    button {
      padding: rem(4) rem(8);
      border: none;
      border-radius: rem(4);
      cursor: pointer;
      font-size: rem(14);

      &.btn-save {
        background-color: $purple;
        color: white;
      }

      &.btn-cancel {
        background-color: $red-color;
        color: white;
      }
    }
  }
}

.price-display {
  display: flex;
  align-items: center;
  gap: rem(8);

  .btn-edit-price {
    background: none;
    border: none;
    color: $purple;
    cursor: pointer;
    padding: rem(4);
    font-size: rem(16);
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }
}
</style>