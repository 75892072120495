<script setup>
import { ref, useHead } from '@/js/import/importHead.js';
import { ServiceEnd, GroupButton } from '@/js/import/importComponents.js';
import { tente_rouge_exterieur, tente_rouge_interieur } from '@/js/import/images/service/serivceImages.js';


const title = ref('Tente rouge')

useHead({
  title: title.value,
  meta: [
    { name: 'description', content: 'Le tente rouge est un traitement de la douleur post-partum qui consiste à réduire la douleur et à améliorer la sensation de liberté dans les membres de la femme. Il est souvent utilisé en tant que traitement post-partum pour réduire les symptômes de douleur, de dépression et de douleur musculaire.' },
    { name: 'keywords', content: 'tente rouge, douleur post-partum, traitement post-partum, douleur, dépression, douleur musculaire' }
  ],
  script: [
    { src: 'https://schema.org/Article', type: 'application/ld+json' },
  ],
});

</script>

<template>
<div>
  <article class="service-content parallax" itemscope itemtype="https://schema.org/Article">
    <div class="background-scroll-container">
      <img src="@/assets/tente-rouge-exterieur.jpg" itemprop="image" alt="Image de la tente rouge extérieure" style="display: none;" /> <!-- Image pour SEO -->
    </div>
    <header class="box-shadow-1 header header-parallax-bg" itemprop="headline">
      <h1>{{ title }}</h1>
    </header>

    <section class="container" itemprop="articleBody">
      <div class="logo">
        <img class="img-logo" src="@/assets/logo_nathalite_doula.png" alt="Logo de NatHalité Doula" itemprop="image"/>
      </div>


      <div class="service-content__block" itemprop="articleSection">
        <div class="service-content__image">
          <div class="float-right">
              <img class="service-img-right" :src="tente_rouge_interieur" alt="Illustration de la tente rouge" />
          </div>
          <div class="service-content__image-text container ">
            <p>
              <strong>"La Tente rouge" </strong> est un <strong>roman</strong> écrit par   
            <span itemprop="author">Anita Diamant</span>, publié en <span itemprop="datePublished" 
            content="1997">1997</span>. L'histoire est une réécriture féministe de la vie de Dina, 
            un personnage mineur de la Bible. Le livre tire son nom de la <strong>"tente rouge"</strong>, 
            un <strong>espace sacré</strong> où les <strong>femmes</strong> de la tribu se retirent pendant 
            leurs menstruations, pour <strong>accoucher</strong> ou pour se rétablir après une maladie.
            </p>
            <p>
              Dans le contexte du roman, la <strong>tente</strong> rouge devient un symbole 
              puissant de la solidarité <strong>féminine</strong>, de la transmission des savoirs entre les
              générations de <strong>femmes</strong>, et de la célébration de la <strong>féminité</strong>. 
              Les <strong>femmes</strong> y partagent leurs expériences, leurs connaissances, et leurs histoires personnelles. 
              La <strong>tente rouge</strong> est décrite comme un lieu de refuge, de guérison et de renforcement 
              du lien entre les <strong>femmes</strong>.
            </p>
            <p>
              <strong>"La Tente rouge"</strong> explore des thèmes tels que la <strong>maternité</strong>, 
              la <strong>sororité</strong>, la <strong>sexualité</strong> et la <strong>spiritualité</strong>, 
              tout en donnant une voix et une perspective aux <strong>femmes</strong> de l'époque biblique, 
              souvent absentes ou marginalisées dans les récits traditionnels. 
              Le roman de Diamant est reconnu pour son écriture riche et immersive, 
              et pour sa capacité à donner vie à un monde ancien à travers 
              les yeux de ses <strong>personnages féminins</strong>.
            </p>
          </div>
        </div>
      </div>
      <div class="service-end container">
          <ServiceEnd 
            :price="25" 
            description="La séance de tente rouge" 
            infoText="Pour plus d'information, n'hésitez pas à nous contacter" 
          />
        </div>
    </section>
  </article>
</div>
</template>

<style lang="scss" scoped>
@import '../../styles/services_item.scss';

.container {
  @include media-breakpoint-down(md) {
    padding: rem(8) rem(20);

  }
}

.parallax {
  background-image: url('@/assets/tente-rouge-exterieur.jpg');
}

.parallax::before {

  background-color: rgba(58, 50, 59, 0.2); /* Couleur de fond avec opacité */
}

.service-content{
  position: relative;
}

.service-content__block {
  background-color: #6c5f4c57;
}

.service-content__image-text {
  display: block;

  p {
    margin-bottom: rem(32);
  }
}

/* service-content-parallax */



.service-content-p {
  background-color: rgba(58, 50, 59, 0.5);
}

.service-content__image {

  gap: 3rem;

  @include media-breakpoint-down(md) {
    gap: 0;
    margin-top: 0;
  }

  .service-content__image-text {
    color: white;
    @include font-size-responsive(rem(12.8), rem(19.2));
  }
}
</style>